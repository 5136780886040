import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import LoginForm from './forms/LoginForm';

interface Props {
  redirectPath: string;
}

const Login: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(true);

  const redirectPath = location.state?.from?.pathname || '/';

  const handleLogin = async (values: { username: string; password: string }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });

      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem('authToken', token);
        setModalOpen(false);
        if(location.pathname === redirectPath) {
          window.location.reload();
        } else {
          navigate(redirectPath);
        }
      }

      if (!response.ok) {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() =>  {
    // window.location.reload();

  },[modalOpen]);

  return (
    <div>
      <Modal
      title={"Login to view content"}
        centered
        open={modalOpen}
        closable={false}
        footer={null}
      >
          <LoginForm onSubmit={handleLogin} />
      </Modal>
    </div>
  );
};

export default Login;
