import React, {useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, Menu, Col, Row, Avatar, Space, Dropdown, Drawer, Button } from 'antd';
import { UserOutlined, HomeOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import Login from './Components/Login';
import PrivateRoute from './Components/PrivateRoute';
import SecurePage from './Components/SecurePage';
import { useAuth } from './hooks/useAuth';
import Overview from './Pages/Overview';
import StockDetailsPage from './Pages/Stocks/StockDetailsPage';

const { Header, Content } = Layout;

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [drawerVisible, setDrawerVisible] = useState(false);


    // Handler for menu item clicks
    const handleMenuClick = (e: { key: string }) => {
      if (e.key === 'logout') {
        // Clear token and redirect to login
        localStorage.removeItem('authToken');
        window.location.href = '/';
      }
    };

    const userMenu = (
      <Menu onClick={handleMenuClick}>
        {!isAuthenticated && (
          <Menu.Item key="login" icon={<UserOutlined />}>
            <a href="/login">Login</a>
          </Menu.Item>
        )}
        {isAuthenticated && (
          <>
            <Menu.Item key="profile" icon={<UserOutlined />}>
              <a href="/profile">Profile</a>
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </>
        )}
      </Menu>
    );

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="header">
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            {/* Logo */}
            <Col>
            <a href='/' key={1}>
              <div className="logo" />
            </a>
              
            </Col>

            {/* Navigation Menu */}
            <Col>
            </Col>

            {/* User Actions (optional, e.g., Logout) */}
            <Col>
              <Row>
              <Dropdown overlay={userMenu} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <Avatar icon={<UserOutlined />} />
                    </Space>
                  </a>
                </Dropdown>
                <Col>
                <a onClick={(e) => e.preventDefault()}>
                  <Space onClick={() => setDrawerVisible(true)}>
                    <Avatar icon={<MenuOutlined />} />
                  </Space>
                </a>
                </Col>
              </Row>
              <Drawer
              title="Menu"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
            >
              <Menu mode="inline">
                <Menu.Item key="1" icon={<HomeOutlined />}>
                  <a href="/">Home</a>
                </Menu.Item>
                <Menu.Item key="2" icon={<UserOutlined />}>
                  <a href="/profile">Profile</a>
                </Menu.Item>
                <Menu.Item key="3" icon={<LogoutOutlined />}>
                  <a href="/logout">Logout</a>
                </Menu.Item>
                {/* Add more items */}
              </Menu>
            </Drawer>
              
            </Col>
          </Row>
        </Header>
        <Layout>
          <Layout className='main-content-container' style={{ minHeight: 280, }}>
            <Content
              style={{
                minHeight: 280,
              }}
            >
              <Routes>
                {/* <Route path="/login" element={<Login />} /> */}
                <Route
                  path="/"
                  element={<PrivateRoute element={<Overview />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/weatherforecast"
                  element={<PrivateRoute element={<SecurePage />} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="/stock/:ticker"
                  element={<PrivateRoute element={<StockDetailsPage />} isAuthenticated={isAuthenticated} />}
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;