import React, { useEffect, useState } from 'react';
import { Slider, Button, Row, Col } from 'antd';

interface SliderFilterDropdownProps {
  valueRange: [number, number];
  minValue: number;
  maxValue: number;
  setValueRange: (value: [number, number]) => void;
  setSelectedKeys: (keys: string[]) => void;
  confirm: () => void;
  clearAllFilters: boolean;
}

const FilterSlider: React.FC<SliderFilterDropdownProps> = ({
  valueRange,
  minValue,
  maxValue,
  setValueRange,
  setSelectedKeys,
  confirm,
  clearAllFilters,
}) => {
  const defaultRange: [number, number] = [minValue, maxValue];

  useEffect(() => {
    console.log("FILTER STATUS: " + clearAllFilters);
    if (clearAllFilters) {
      console.log("RESETTING filter");
      handleReset();
    }
  }, [clearAllFilters]); // Depend on clearAllFilters

  const handleReset = () => {
    setValueRange(defaultRange); // Reset the slider to the default range
    setSelectedKeys([`${defaultRange[0]}-${defaultRange[1]}`]); // Reset the selected keys
  };

  return (
    <div style={{ padding: 8 }}>
      <Slider
        range
        min={minValue ?? 0}
        max={maxValue ?? 100}
        value={valueRange} // Controlled slider value
        onChange={(value) => {
          setValueRange([value[0], value[1]]);
          setSelectedKeys([`${value[0]}-${value[1]}`]); // Pass the selected range as a string
        }}
        style={{ width: 200 }}
      />
      <Row gutter={8} style={{ marginTop: 8 }}>
        <Col span={12}>
          <Button onClick={handleReset} style={{ width: '100%' }}>
            Reset
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => confirm()}
            style={{ width: '100%' }}
          >
            Apply Filter
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FilterSlider;