import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Login from '../Components/Login';

interface PrivateRouteProps {
  element: React.ReactElement;
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, isAuthenticated }) => {
  const location = useLocation();
  // return isAuthenticated ? element : <Navigate to="/login" />;
  return isAuthenticated ? element : <Login redirectPath={location.pathname} />
};

export default PrivateRoute;