import React, {useState, useRef} from 'react';
import { Table, Button, Input, Slider, Card } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { stockData } from '../../DummyData/stockdata'; 
import type { TableColumnType } from 'antd';
import FilterSlider from '../utils/FilterSlider';
import type {TableProps} from 'antd';

interface StockData {
  key: string;
  name: string;
  ticker: string;
  price: number;
  pe: number;
  peg: number;
  eps: number;
  revenue1yr: number;
  revenue3yrs: number;
  revenue5yrs: number;
}

const StockTable: React.FC = () => {
  // const tableRef = useRef<any>(null);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any>>({});
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [peRange, setPeRange] = useState<[number, number]>([0, 100]);
  const [pegRange, setPegRange] = useState<[number, number]>([0, 100]);
  const [epsRange, setEpsRange] = useState<[number, number]>([0, 100]);
  const [revenue1yrRange, setRevenue1yrRange] = useState<[number, number]>([0, 100]);
  const [revenue3yrRange, setRevenue3yrRange] = useState<[number, number]>([0, 100]);
  const [revenue5yrRange, setRevenue5yrRange] = useState<[number, number]>([0, 100]);

  const handleClearAll = () => {
    setClearAllFilters(true);
    window.location.reload();
  }
 
  const columns: TableColumnType<StockData>[] =  [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left',
    },
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a: StockData, b: StockData) => a.price - b.price,
      render: (text: number) => `$${text.toFixed(2)}`,
    },
    {
      title: 'P/E',
      dataIndex: 'pe',
      key: 'pe',
      sorter: (a: StockData, b: StockData) => a.pe - b.pe,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <FilterSlider
          valueRange={peRange}
          minValue={0}
          maxValue={100}
          setValueRange={setPeRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minPe, maxPe] = (value as string).split('-').map(Number);
        return record.pe >= minPe && record.pe <= maxPe;
      },
      render: (text: number) => text.toFixed(2),
    },
    {
      title: 'PEG',
      dataIndex: 'peg',
      key: 'peg',
      sorter: (a: StockData, b: StockData) => a.peg - b.peg,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <FilterSlider
          valueRange={pegRange}
          minValue={0}
          maxValue={100}
          setValueRange={setPegRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minPeg, maxPeg] = (value as string).split('-').map(Number);
        return record.peg >= minPeg && record.peg <= maxPeg;
      },
      render: (text: number) => text.toFixed(2),
    },
    {
      title: 'EPS',
      dataIndex: 'eps',
      key: 'eps',
      sorter: (a: StockData, b: StockData) => a.eps - b.eps,
      filterDropdown: ({ setSelectedKeys, confirm}) => (
        <FilterSlider
          valueRange={epsRange}
          minValue={0}
          maxValue={100}
          setValueRange={setEpsRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minEps, maxEps] = (value as string).split('-').map(Number);
        return record.eps >= minEps && record.eps <= maxEps;
      },
      render: (text: number) => text.toFixed(2),
    },
    {
      title: 'Revenue % 1 yr',
      dataIndex: 'revenue1yr',
      key: 'revenue1yr',
      sorter: (a: StockData, b: StockData) => a.revenue1yr - b.revenue1yr,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <FilterSlider
          valueRange={revenue1yrRange}
          minValue={0}
          maxValue={100}
          setValueRange={setRevenue1yrRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minRev1Yr, maxRev1Yr] = (value as string).split('-').map(Number);
        return record.revenue1yr >= minRev1Yr && record.revenue1yr <= maxRev1Yr;
      },
      render: (text: number) => `${text.toFixed(2)}%`,
    },
    {
      title: 'Revenue % 3 yrs',
      dataIndex: 'revenue3yrs',
      key: 'revenue3yrs',
      sorter: (a: StockData, b: StockData) => a.revenue1yr - b.revenue1yr,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <FilterSlider
          valueRange={revenue3yrRange}
          minValue={0}
          maxValue={100}
          setValueRange={setRevenue3yrRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minRev3Yr, maxRev3Yr] = (value as string).split('-').map(Number);
        return record.revenue3yrs >= minRev3Yr && record.revenue3yrs <= maxRev3Yr;
      },
      render: (text: number) => `${text.toFixed(2)}%`,
    },
    {
      title: 'Revenue % 5 yrs',
      dataIndex: 'revenue5yrs',
      key: 'revenue5yrs',
      sorter: (a: StockData, b: StockData) => a.revenue5yrs - b.revenue5yrs,
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <FilterSlider
          valueRange={revenue5yrRange}
          minValue={0}
          maxValue={100}
          setValueRange={setRevenue5yrRange}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearAllFilters={clearAllFilters}
        />
      ),
      onFilter: (value, record: StockData) => {
        const [minRev5Yr, maxRev5Yr] = (value as string).split('-').map(Number);
        return record.revenue5yrs >= minRev5Yr && record.revenue5yrs <= maxRev5Yr;
      },
      render: (text: number) => `${text.toFixed(2)}%`,
    },
  ];


  return (
    <div>
      <Card title={
        <div style={{marginTop: '12px'}}>
          <Button
            type="default"
            onClick={handleClearAll}
            style={{ marginBottom: 16 }}
            icon={<ClearOutlined />}
          >
            Clear filters
          </Button>
        </div>
        }>
        <Table
            dataSource={stockData}
            columns={columns}
            pagination={{ pageSize: 10 }}
            bordered
            scroll={{x: 1100}}
          />
      </Card>
          
    </div>
  );
};

export default StockTable;
