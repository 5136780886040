import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Spin, Typography } from 'antd';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, CategoryScale, Tooltip, Legend } from 'chart.js';
// import { format, parseISO } from 'date-fns'; // Import date-fns for date formatting

ChartJS.register(LinearScale, PointElement, LineElement, CategoryScale, Tooltip, Legend);

const { Title } = Typography;

const StockDetailsPage: React.FC = () => {
  const { ticker } = useParams<{ ticker: string }>();
  const [stockData, setStockData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStockData = async () => {
      setLoading(true);
      const url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${ticker?.toUpperCase()}&interval=5min&apikey=demo`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        setStockData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stock data:', error);
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  if (loading) {
    return <Spin tip="Loading stock data..." />;
  }

  if (!stockData || !stockData['Time Series (5min)']) {
    return <Typography.Text>No data available for {ticker?.toUpperCase()}</Typography.Text>;
  }

  const timeSeries = stockData['Time Series (5min)'];
  const labels = Object.keys(timeSeries);
  const closePrices = labels.map((time) => parseFloat(timeSeries[time]['4. close']));

  const formattedLabels = labels
    .map((label) => new Date(label).toLocaleTimeString()) // Format the date
    .reverse();

  const data = {
    labels: formattedLabels,
    datasets: [
      {
        label: '',
        data: closePrices.reverse(),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false,
      },
      title: {
        display: false,
        text: `Close Prices for ${ticker?.toUpperCase()}`,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 25,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>{ticker?.toUpperCase()}</Title>
      <Line data={data} options={options} />
    </div>
  );
};

export default StockDetailsPage;
